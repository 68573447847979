import React, { useState } from "react";
import { plausibleEvent } from "../../utilities/helper";
import { useCatalogFilter } from "../../context/CatalogContext";

type FilterButtons = {
    all: boolean;
    chairs: boolean;
    barstools: boolean;
    bowlchairs: boolean;
    armrests: boolean;
    wood: boolean;
};

export const SearchAndFilter = () => {
    const { setFilter } = useCatalogFilter();
    const [filterButtons, setFilterButtons] = useState<FilterButtons>({
        all: true,
        armrests: false,
        barstools: false,
        bowlchairs: false,
        chairs: false,
        wood: false,
    });
    const [search, setSearch] = useState<string>("");

    const handleFilterButtonClick = (value: string) => {
        const tempFilterButtons = { ...filterButtons };
        let filter: string[] = [];
        if (value in tempFilterButtons) {
            plausibleEvent("filter-selected", value);
            tempFilterButtons[value] = !tempFilterButtons[value];
            if (value === "all" && tempFilterButtons.all) {
                filter = ["armrest", "barstool", "bowlchair", "chair", "wood"];
                tempFilterButtons.armrests = false;
                tempFilterButtons.barstools = false;
                tempFilterButtons.bowlchairs = false;
                tempFilterButtons.chairs = false;
                tempFilterButtons.wood = false;
            } else if (
                value === "all" &&
                !tempFilterButtons.all &&
                !(
                    tempFilterButtons.armrests ||
                    tempFilterButtons.barstools ||
                    tempFilterButtons.bowlchairs ||
                    tempFilterButtons.chairs ||
                    tempFilterButtons.wood
                )
            ) {
                filter = ["armrest", "barstool", "bowlchair", "chair", "wood"];
                tempFilterButtons.armrests = true;
                tempFilterButtons.barstools = true;
                tempFilterButtons.bowlchairs = true;
                tempFilterButtons.chairs = true;
                tempFilterButtons.wood = true;
            } else {
                tempFilterButtons.all = !(
                    tempFilterButtons.armrests ||
                    tempFilterButtons.barstools ||
                    tempFilterButtons.bowlchairs ||
                    tempFilterButtons.chairs ||
                    tempFilterButtons.wood
                );
                if (tempFilterButtons.all) {
                    filter = ["armrest", "barstool", "bowlchair", "chair", "wood"];
                } else {
                    if (tempFilterButtons.armrests) {
                        filter.push("armrest");
                    }
                    if (tempFilterButtons.barstools) {
                        filter.push("barstool");
                    }
                    if (tempFilterButtons.bowlchairs) {
                        filter.push("bowlchair");
                    }
                    if (tempFilterButtons.chairs) {
                        filter.push("chair");
                    }
                    if (tempFilterButtons.wood) {
                        filter.push("wood");
                    }
                }
            }
        }

        setFilter({ filter: filter, page: 0 });
        setFilterButtons(tempFilterButtons);
    };

    return (
        <div className={"filter-and-search"}>
            <div className={"filter"}>
                <button
                    className={`filter-button all ${filterButtons.all ? "active" : ""}`}
                    onClick={() => handleFilterButtonClick("all")}
                >
                    Alle
                </button>
                <button
                    className={`filter-button chairs ${filterButtons.chairs ? "active" : ""}`}
                    onClick={() => handleFilterButtonClick("chairs")}
                >
                    Stühle
                </button>
                <button
                    className={`filter-button barstools ${filterButtons.barstools ? "active" : ""}`}
                    onClick={() => handleFilterButtonClick("barstools")}
                >
                    Barhocker
                </button>
                <button
                    className={`filter-button bowlchairs ${filterButtons.bowlchairs ? "active" : ""}`}
                    onClick={() => handleFilterButtonClick("bowlchairs")}
                >
                    Schalensessel
                </button>
                <button
                    className={`filter-button armrests ${filterButtons.armrests ? "active" : ""}`}
                    onClick={() => handleFilterButtonClick("armrests")}
                >
                    Armlehne
                </button>
                <button
                    className={`filter-button armrests ${filterButtons.wood ? "active" : ""}`}
                    onClick={() => handleFilterButtonClick("wood")}
                >
                    Holzstuhl
                </button>
            </div>
            <div className={"search-block"}>
                <input
                    className={"search-input"}
                    type={"text"}
                    placeholder={"Suche"}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                            setFilter({ search: search, page: 0 });
                        }
                    }}
                    onBlur={() => {
                        setFilter({ search: search, page: 0 });
                        plausibleEvent("search", search);
                    }}
                />
                <button
                    type={"submit"}
                    className={"only-icon button search-button"}
                    onClick={() => {
                        setFilter({ search: search, page: 0 });
                    }}
                >
                    Suchen
                </button>
            </div>
        </div>
    );
};
