import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Autoplay, EffectFade, Navigation, Pagination, Scrollbar } from "swiper/modules";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Scrollbar]);

new Swiper(".customer-swiper", {
    direction: "horizontal",
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 30,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
});

new Swiper(".swiper-news", {
    direction: "horizontal",
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 30,
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});
